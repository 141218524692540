<template>
  <div>
    <iframe :src="resumeUrl" width="100%" height="600"></iframe>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from "vuex";
import axios from 'axios';

const route = useRoute();
const pageLoaded = ref(false);
const store = useStore();
const resumeUrl = ref('');

const src = computed(() => `data:application/pdf:base64,${resumeUrl.value}`)

const getResumeUrl = async () => {
  try {
    const url = await store.getters['global/getResumeUrl'](route.params.uri);
    resumeUrl.value = `${url}`;
  } catch (error) {

  }
};

onMounted(async () => {
  //pageLoaded.value = true;
  //https://res.cloudinary.com/
  await getResumeUrl()
});
</script>
